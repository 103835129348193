<template>
    <div>
        <h1 style="font-weight: bold;">Akcije in popusti</h1>
        <b-row class="mt-1 d-flex">
            <b-col sm="12" md="6" lg="3" :key="index" v-for="(item, index) in webShopItems" class="mt-2">
                <saleCard :object="item" ribbon="TOP CENA"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {BRow, BCol} from 'bootstrap-vue'
    import saleCard from '../../Components/SaleCard'

    export default {
        components: {
            saleCard,
            BRow,
            BCol
        },

        data() {
            return {
                categories: null,
                webShopItems: [],
                checkType: null
            }
        },
        methods: {
            async loadCategories() {
                const thisIns = this
                thisIns.showLoader = true

                await thisIns.$http.get('/api/user/v1/web_shop/category')
                    .then(res => {
                        this.categories = res.data
                        this.$store.state.app.webShopCategories = this.categories
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju izdelkov je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            getQueryParams() {
                const params = []
                for (const category of this.categories) {
                    for (const item of category.children) {
                        params.push(`split_categories.[]=${item.bbis_id}&&`)
                    }
                }

                return params
            },
            async loadItems() {
                const thisIns = this
                thisIns.showLoader = true

                const queryParams = this.getQueryParams()

                await thisIns.$http.get(`/api/user/v1/web_shop/?${queryParams}&perPage=12`)
                    .then(res => {
                        this.webShopItems = res.data.items ?? []
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju izdelkov je prišlo do napake!\n${error.message}`)
                    }).finally(() => {
                        thisIns.showLoader = false
                    })
            }
        },
        async beforeMount() {
            await this.loadCategories()
            await this.loadItems()
        }
    }
</script>

<style scoped>
.category > [class*='col-'] {
  display: flex;
  align-items: center
}
</style>
