<template>
    <b-card class="card main-card border m-auto h-100">
        <div class="badge">
            TOP CENA
        </div>

        <div class="d-flex justify-content-center">
            <router-link :to="{name: 'web-shop-item', params: {item_id: object.id}}">
                <b-card-img v-if="object.main_picture !== ''" class="main-photo" :src="'/api/user/v1/web_shop/product_image/500_' + object.main_picture"/>
                <b-card-img v-else class="main-photo" :src="require('@/assets/images/esoseska/no-image.jpg')"/>
            </router-link>
        </div>
        <div class="text-center">
            <router-link :to="{name: 'web-shop-item', params: {item_id: object.id}}">
                <h2 class="mb-0 mt-2 cursor-pointer title">{{object.description_1}}</h2>
            </router-link>
            <h3 class="sub-title">{{object.description_2}}</h3>
        </div>
        <template #footer>
            <div class="text-center">
                <template v-if="object.discounted">
                    <div class="d-flex flex-wrap justify-content-center" style="column-gap: 1rem; margin-top: 5px;">
                        <div class="d-flex flex-column align-items-start">
                            <span class="text-left" style="font-size: 12px;">REDNA CENA</span>
                            <h1 class="price-discount">{{$convertPriceIntegerToFloat(object.price)}}€</h1>
                        </div>
                        <div style="border-left: 2px solid #009ED3; width: 2px;"/>
                        <div class="d-flex flex-column align-items-start">
                            <span class="text-left text-primary" style="font-size: 12px;">CENA S KUPONI</span>
                            <h1 class="price-discount text-primary">{{$convertPriceIntegerToFloat(object.price_discounted)}}€</h1>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <h1 class="price">{{$convertPriceIntegerToFloat(object.price)}}€</h1>
                </template>
                <router-link :to="{name: 'web-shop-item', params: {item_id: object.id}}">
                    <b-button v-if="object.in_stock > 0" class="mt-1 p-1 order-button" pill variant="primary">Dodaj v košarico</b-button>
                    <b-button v-else class="mt-1 p-1 order-button" pill variant="primary">Odpri</b-button>
                </router-link>
            </div>
        </template>
    </b-card>
</template>

<script>
    import {BCard, BButton, BCardImg} from 'bootstrap-vue'
    export default
        {
            components: {BCard, BButton, BCardImg},
            props: {
                object: {
                    type: Object,
                    required: true
                },
                ribbon: {
                    type: String,
                    required: false
                }
            },
            methods: {
                onClick() {
                    //this.$router.push({ name: 'item-page', params: {category: 'pohistvo', categoryName: 'bela-tehnika', itemName: this.object.description_1.toLowerCase().replace(/\s+/g, '-')} })
                    this.$router.push({name: 'web-shop-item', params: {item_id: this.object.id}})
                    this.$scrollToTop()
                }
            }
        }
</script>

<style scoped>
  .badge {
    border-radius: 0 !important;
    position: sticky;
    background-color: #72A5D8;
    top: 15px;
    left: 10px !important;
    font-weight: 700;
    font-size: 20px;
    padding-right: 1.5rem;
    clip-path: polygon(0 0, 0 100%, 100% 100%, 93% 100%, 100% 0);
  }
  .order-button{
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
  .order-button-no-stock{
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    background: #b6b6b6 !important;
    border: none !important;
  }

  .order-button-no-stock:hover, .order-button-no-stock:active, .order-button-no-stock:focus{
    box-shadow: none !important;
  }

  .card {
    /*max-width: 25rem;*/
    width: 100%;
    border: none !important;
    border-radius:0;
    box-shadow: none;
  }
  .font {
    font-weight: bold;
  }
  .title {
    font-size: 20px;
    line-height: 25px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5em !important;
  }
  .sub-title {
    font-size: 20px;
    line-height: 25px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .price {
    font-weight: 700;
    font-size: 25px;
    line-height: 31px;
  }
  .price-discount {
    font-weight: 700;
    font-size: 20px;
  }
  .main-photo {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .card-footer {
    border: none;
    padding-top: 0px;
  }

  .card-body{
    padding-bottom: 0px;
  }
</style>
